// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/icons/header-menu/arrow-left.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-1103ac6b]:root{--light_text_color:#666}.drawer-menu__content-child[data-v-1103ac6b]{display:none}.drawer-menu__content-child--open[data-v-1103ac6b]{display:block}.drawer-menu-item[data-v-1103ac6b]{padding:0;background-color:#fff}.drawer-menu-item.drawer-menu-item--discrete[data-v-1103ac6b]{background-color:transparent}.drawer-menu-item.drawer-menu-item--discrete a[data-v-1103ac6b]{padding-left:49px;border-color:#e9e9ed}.drawer-menu-item.return.active a[data-v-1103ac6b]{color:#ec8ab3}.drawer-menu-item.return a[data-v-1103ac6b]{position:relative;font-weight:700;padding-left:36px}.drawer-menu-item.return a[data-v-1103ac6b]:before{content:\"\";position:absolute;top:20px;left:17px;width:8px;height:10px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50%/contain no-repeat}.drawer-menu-item.drawer-menu-item--logout[data-v-1103ac6b]{background:none;padding:20px 0;font-size:11px;text-align:center;color:#555}.drawer-menu-item.drawer-menu-item--logout p[data-v-1103ac6b]{margin:0;text-align:center}.drawer-menu-item.drawer-menu-item--logout .logout-button[data-v-1103ac6b]{padding:5px 0;border:0;display:flex;align-items:center;justify-content:center}.drawer-menu-item.drawer-menu-item--logout .logout-button svg[data-v-1103ac6b]{display:inline;margin-right:2px}.drawer-menu-item a[data-v-1103ac6b]:active{color:#ec8ab3}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
