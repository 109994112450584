<template>
  <div class="drawer-menu__content">
    <ul>
      <client-only>
        <header-drawer-content-item
          v-for="item in featuredItems"
          :key="item.position"
          :item="item"
          :methods="drawerContentItemMethods"
        />
        <header-drawer-content-item
          v-for="item in items"
          :key="item.position"
          :item="item"
          :methods="drawerContentItemMethods"
        />
        <header-drawer-content-item
          v-if="$isBrStore"
          :item="{
            url: '/find-order',
            name: $t('header.track_order'),
          }"
          :methods="drawerContentItemMethods"
        />
        <header-drawer-content-item
          v-if="$isBrStore"
          :item="{
            url: 'https://corporativo.gocase.com.br/',
            name: $t('header.corporate_gifts'),
          }"
          :methods="drawerContentItemMethods"
        />
        <header-drawer-content-item
          v-if="$isBrStore"
          :item="{
            url: 'https://reseller.gocase.com.br/pt-BR/welcome',
            name: $t('header.become_reseller'),
          }"
          :methods="drawerContentItemMethods"
        />
        <header-drawer-content-item
          v-if="$isBrStore"
          :item="{
            url: 'https://www.gocase.com.br/central-de-ajuda',
            icon: 'help-icon',
            name: $t('help_center'),
          }"
          :methods="drawerContentItemMethods"
          discrete
        />
      </client-only>
    </ul>
    <ul v-if="isLogged">
      <header-drawer-content-item
        :item="{
          url: '/orders',
          icon: 'order-icon',
          name: $t('header.my-orders'),
        }"
        :methods="drawerContentItemMethods"
        discrete
      />
      <header-drawer-content-item
        :item="{
          url: '/account/edit',
          icon: 'edit-icon',
          name: $t('header.edit-account-info'),
        }"
        :methods="drawerContentItemMethods"
        discrete
      />
      <header-drawer-content-item
        :item="{
          url: '/addresses',
          icon: 'address-icon',
          name: $t('header.address-book'),
        }"
        :methods="drawerContentItemMethods"
        discrete
      />
      <header-drawer-content-item
        :item="{
          url: '/protocols',
          icon: 'protocol-icon',
          name: $t('header.protocols'),
        }"
        :methods="drawerContentItemMethods"
        discrete
      />
      <!-- REWARDS - Comentado também no UserWidget e ReferralWidget
      <header-drawer-content-item
        v-if="$isBrStore"
        :item="{
          url: '/rewards',
          icon: 'rewards-icon',
          name: $t('header.rewards'),
        }"
        :methods="drawerContentItemMethods"
        discrete
      />
      -->
      <header-drawer-content-item
        :item="{
          url: '#',
          css_class: 'drawer-menu-item--logout',
        }"
        :methods="drawerContentItemMethods"
      >
        <p>{{ $t('header.hello') }}, {{ firstName }}</p>
        <logout-button class="" icon />
      </header-drawer-content-item>
    </ul>
    <ul v-else>
      <header-drawer-content-item
        :item="{
          url: '/login',
          icon: 'login-icon',
          name: $t('header.login'),
        }"
        :methods="drawerContentItemMethods"
        discrete
      />
    </ul>
  </div>
</template>
<script>
import copyObject from '@/commun/utils/copyObject'
import HeaderDrawerContentItem from './header-drawer-content-item/HeaderDrawerContentItem.vue'
import LogoutButton from '~/components/shared/logout-button/LogoutButton.vue'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import isExternalLink from '~/utils/isExternalLink'
import menulable from '~/mixins/menulable'

export default {
  name: 'HeaderDrawerContent',
  components: {
    HeaderDrawerContentItem,
    LogoutButton,
  },
  mixins: [menulable, pushDataLayer],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters.isLogged
    },
    firstName() {
      // eslint-disable-next-line camelcase
      return this.$store.state.user?.first_name
    },
    drawerContentItemMethods() {
      return {
        hasChild: this.hasChild,
        adaptedUrl: this.adaptedUrl,
        opening: this.opening,
        nameSlug: this.nameSlug,
        name: this.name,
        closing: this.closing,
        redirectToInternalPage: this.redirectToInternalPage,
        childrenItems: this.childrenItems,
        nextLevel: this.nextLevel,
        trackClickToGTM: this.trackClickToGTM,
      }
    },
    featuredItems() {
      const featured = []

      // eslint-disable-next-line camelcase
      const ctaMenu = copyObject(this.$store.state.siteStore?.cta_menu?.[0])

      if (ctaMenu) {
        ctaMenu.position = 0.1
        featured.push(ctaMenu)
      }

      return featured
    },
  },
  methods: {
    trackClickToGTM(item) {
      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'track_header_click_mobile',
        eventLabel: item.name,
      })
    },
    menuRedirect(item) {
      this.drawerContentItemMethods.trackClickToGTM(item)

      if (
        !this.hasChild(item) &&
        this.adaptedUrl(item) &&
        this.adaptedUrl(item) !== '#'
      ) {
        if (isExternalLink(this.adaptedUrl(item))) {
          window.open(this.adaptedUrl(item), '_blank')
        } else {
          this.redirectToInternalPage(item)
        }
      }
    },
    redirectToInternalPage(item) {
      this.$store.dispatch('drawer/closeDrawer')
      this.$router.push(this.adaptedUrl(item))
      this.resetConfigurations()
    },
    resetConfigurations() {
      document.querySelectorAll('.slide-left').forEach((slideEl) => {
        if (slideEl) {
          slideEl.classList.remove('slide-left')

          slideEl
            .querySelectorAll('.open')
            .forEach((openedEl) => openedEl.classList.remove('open'))
          slideEl
            .querySelectorAll('.active')
            .forEach((activedEl) => activedEl.classList.remove('active'))
        }
      })
    },
    opening(e, item) {
      e.preventDefault()

      if (this.hasChild(item)) {
        const child = e.target
          .closest('.drawer-menu-item')
          .querySelector('.drawer-menu__content-child')
        child.classList.add('drawer-menu__content-child--open')

        this.$nextTick(() => {
          this.openChild(e)
        })
      } else {
        this.menuRedirect(item)
      }
    },
    openChild(e) {
      const el = e.target.closest('li')
      if (el.classList.contains('return')) return
      const childDiv = el.querySelector('div')
      const elParent = el.closest('.drawer-menu__content')

      el.classList.add('open')
      if (elParent) elParent.classList.add('slide-left')
      const menuChild = el.closest('.drawer-menu__content-child')
      if (menuChild) menuChild.classList.add('slide-left')
      if (childDiv) childDiv.classList.add('active')
    },
    closing(e) {
      const animationTime = 200 // Have to be equals to css animation time
      const el = e.target.closest('li')
      if (!el.classList.contains('return')) return
      const elParent = el.closest('div')
      el.classList.add('active')

      setTimeout(function () {
        const openedItem = el.closest('.drawer-menu-item.open')
        if (openedItem) openedItem.classList.remove('open')
        el.classList.remove('active')
      }, animationTime + 300)

      setTimeout(function () {
        if (elParent) elParent.classList.remove('active')
      }, animationTime)

      if (elParent && elParent.classList.contains(`mobile-menu__level-1`)) {
        const itemContainer = el.closest('.drawer-menu__content')
        if (itemContainer) itemContainer.classList.remove('slide-left')
      } else {
        const itemContainer = el.closest(
          '.drawer-menu__content-child.slide-left'
        )
        if (itemContainer) itemContainer.classList.remove('slide-left')
      }

      const child = e.target.parentElement.parentElement.parentElement.parentElement.querySelector(
        '.drawer-menu__content-child'
      )
      child.classList.remove('drawer-menu__content-child--open')
    },
  },
}
</script>
