<template functional>
  <li
    :class="[
      (props.item && props.item.css_class) || '',
      'drawer-menu-item',
      {
        haschild: props.methods.hasChild(props.item),
        'drawer-menu-item--discrete': props.discrete,
      },
    ]"
    v-on="listeners"
  >
    <slot>
      <a
        v-if="props.discrete"
        :class="['drawer-menu-item__icon', props.item && props.item.icon]"
        :href="props.methods.adaptedUrl(props.item)"
        @click.prevent="(e) => props.methods.opening(e, props.item)"
        >{{ props.methods.name(props.item) }}</a
      >
      <a
        v-else
        :class="props.methods.nameSlug(props.item)"
        :href="props.methods.adaptedUrl(props.item)"
        @click.prevent="(e) => props.methods.opening(e, props.item)"
        >{{ props.methods.name(props.item)
        }}<i
          v-if="props.methods.hasChild(props.item)"
          class="icon arrow_carrot-right"
        ></i
      ></a>
    </slot>
    <div
      v-show="props.methods.hasChild(props.item)"
      :class="[
        `mobile-menu__level-${props.level || 1}`,
        'drawer-menu__content-child',
      ]"
    >
      <ul v-if="props.methods.childrenItems(props.item).length > 0">
        <li class="drawer-menu-item return" @click="props.methods.closing">
          <a href="#" @click.prevent>{{ parent.$t('header.back') }}</a>
        </li>
        <li
          v-if="props.item.url && props.item.url !== '#'"
          class="drawer-menu-item"
        >
          <a
            :href="props.methods.adaptedUrl(props.item)"
            @click.prevent="props.methods.redirectToInternalPage(props.item)"
            >{{ parent.$t('header.see_all') }}</a
          >
        </li>
        <header-drawer-content-item
          v-for="(childItem, idx) in props.methods.childrenItems(props.item)"
          :key="`${(props.level || 1) + 1}${idx}`"
          :item="childItem"
          :level="(props.level || 1) + 1"
          :methods="props.methods"
        />
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: 'HeaderDrawerContentItem',
}
</script>

<style lang="scss" scoped>
.drawer-menu__content-child {
  display: none;
}

.drawer-menu__content-child--open {
  display: block;
}

.drawer-menu-item {
  padding: 0;
  background-color: #fff;

  &.drawer-menu-item--discrete {
    background-color: transparent;

    a {
      padding-left: 49px;
      border-color: #e9e9ed;
    }
  }

  &.return {
    &.active {
      a {
        color: $color_pink;
      }
    }
    a {
      position: relative;
      font-weight: bold;
      padding-left: 36px;

      &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: 17px;
        width: 8px;
        height: 10px;
        background: url('~@/assets/images/icons/header-menu/arrow-left.svg')
          center/contain no-repeat;
      }
    }
  }

  &.drawer-menu-item--logout {
    background: none;
    padding: 20px 0;
    font-size: 11px;
    text-align: center;
    color: #555;

    p {
      margin: 0;
      text-align: center;
    }

    .logout-icon {
      padding: 5px 0;
      background-color: transparent;
      border: 0;
    }

    .logout-button {
      padding: 5px 0;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: inline;
        margin-right: 2px;
      }
    }
  }

  a:active {
    color: $color_pink;
  }
}
</style>
