<template>
  <div
    :class="[
      'global-device-selector',
      {
        'global-device-selector--cart': isCartPage,
      },
    ]"
  >
    <transition name="fade">
      <div v-if="isLoading" class="global-device-selector__backdrop-loading">
        <three-dots-loading />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showingNewDevice"
        class="global-device-selector__backdrop-loading"
      >
        {{ $store.state.globalDevice.name }}
      </div>
    </transition>
    <small class="global-device-selector__title"
      >{{ $t('global_device_question') }}
    </small>
    <div class="global-device-selector__selects">
      <select id="brand" v-model="selectedBrandId" name="brand">
        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
          {{ brand.name }}
        </option>
      </select>
      <select id="device" v-model="selectedDeviceId" name="device">
        <option value="0">{{ $t('choose_device') }}</option>
        <option v-for="device in devices" :key="device.id" :value="device.id">
          {{ device.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import BrandsAndDevicesHeaderFilter from '@/mixins/brands-and-devices-header-filter'

import ThreeDotsLoading from '~/components/loadings/three-dots/ThreeDotsLoading.vue'
export default {
  components: { ThreeDotsLoading },
  mixins: [BrandsAndDevicesHeaderFilter],
  data() {
    return {
      isLoading: false,
      showingNewDevice: false,
    }
  },
  computed: {
    isCartPage() {
      return this.$route.path === '/cart'
    },
  },
  watch: {
    async device(newDevice) {
      if (!newDevice) return
      this.isLoading = true

      const splittedDeviceUrl = newDevice?.url?.split('/')
      const deviceSlug = splittedDeviceUrl[splittedDeviceUrl?.length - 1]

      try {
        const { device } = await this.$axios.$get('/find_device', {
          params: {
            ...this.$store.getters.storeInfo,
            device_slug: deviceSlug,
          },
        })

        if (device) {
          localStorage.setItem('global_device', JSON.stringify(device))
          this.$store.dispatch('setGlobalDevice', device)
        }
        this.showingNewDevice = true
        setTimeout(() => {
          this.showingNewDevice = false
        }, 1500)
      } catch (err) {
        this.$sentry.captureException(
          `Global Device is not defined when changed`,
          {
            splittedDeviceUrl,
            deviceSlug,
            ...(err.response.data || {}),
          }
        )
      } finally {
        this.isLoading = false
      }
    },
    selectedBrandId(_, oldBrand) {
      if (oldBrand) this.selectedDeviceId = 0
    },
    '$store.state.globalDevice'(_, oldGlobalDevice) {
      if (!oldGlobalDevice) this.setConfigsFromGlobalDevice()
    },
  },
  mounted() {
    this.setConfigsFromGlobalDevice()
  },
  methods: {
    async setConfigsFromGlobalDevice() {
      if (this.$store.state.globalDevice) {
        this.selectedBrandId = this.brands.find(
          (bd) => bd.name === this.$store.state.globalDevice.brand
        )?.id

        await this.$nextTick()

        this.selectedDeviceId = this.devices.find((device) => {
          const splittedDeviceUrl = device?.url?.split('/')
          const deviceSlug = splittedDeviceUrl[splittedDeviceUrl?.length - 1]
          return deviceSlug === this.$store.state.globalDevice.slug
        })?.id
      } else {
        this.selectedBrandId = this.brands?.[0].id
        this.selectedDeviceId = 0
      }
    },
  },
}
</script>

<style lang="scss">
.global-device-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: $v4_color_gray;
  position: relative;

  &--cart {
    display: none;
  }

  &__backdrop-loading {
    background: rgba($v4_color_gray, 1);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__title {
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
    display: none;
  }

  &__selects {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;

    select {
      width: 100%;
      height: 40px;
      padding: 5px 0px;
      text-align: center;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 3px;

      &:nth-child(1) {
        margin-right: 5px;
        flex: 1;
      }

      &:nth-child(2) {
        flex: 2;
      }
    }
  }

  @media (min-width: $v4_desktop) {
    flex-direction: row;
    justify-content: center;

    &__title {
      margin-bottom: 0px;
      width: auto;
      margin-right: 10px;
      display: block;
    }

    &__selects select {
      padding: 0;
      height: 30px;

      &:nth-child(1) {
        margin-right: 5px;
        flex: 1;
      }
    }
  }
}
</style>
