<template>
  <div :class="['drawer', 'drawer--left', { 'drawer-open': drawerIsOpened }]">
    <nav
      :class="['drawer-nav', 'mobile-menu', { active: drawerIsOpened }]"
      role="navigation"
    >
      <global-device-selector v-if="$globalDeviceIsActive" />
      <div class="drawer-menu">
        <div
          :class="[
            'mobile-menu__header',
            {
              'mobile-menu__header--blackfriday': $store.state.isBlackFriday,
              'mobile-menu__header--christmas': $store.state.isChristmas,
            },
          ]"
        >
          {{ $t('header.shop_by') }}
          <div class="mobile-menu__close" @click="closeDrawer"></div>
        </div>
        <header-drawer-content
          v-if="headerMenu && headerMenu.length"
          :items="headerMenu"
        />
      </div>
    </nav>
    <div
      :class="['drawer-overlay', { active: drawerIsOpened }]"
      @click="closeDrawer"
    ></div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import GlobalDeviceSelector from '@/components/global-device-selector/GlobalDeviceSelector.vue'
import HeaderDrawerContent from './header-drawer-content/HeaderDrawerContent.vue'
import positionable from '~/mixins/positionable'
import modalable from '~/mixins/modalable'

export default {
  name: 'HeaderDrawer',
  components: { HeaderDrawerContent, GlobalDeviceSelector },
  mixins: [positionable, modalable],
  computed: {
    ...mapState(['siteStore']),
    ...mapGetters({ drawerIsOpened: 'drawer/drawerIsOpened' }),
    headerMenu() {
      // eslint-disable-next-line camelcase
      const substoreMenus = this.siteStore?.substores_menu
      const menu = substoreMenus?.brasil?.length
        ? substoreMenus?.brasil
        : substoreMenus?.us

      if (this.$store.state.isUsStore && menu) {
        // eslint-disable-next-line camelcase
        return this.positioning(menu)
      }
      // eslint-disable-next-line camelcase
      return this.positioning(this.siteStore?.header_menu)
    },
  },
  watch: {
    drawerIsOpened(newBool) {
      this.modalIsOpened(newBool)
    },
  },
  created() {
    if (process.client) this.modalIsOpened(this.drawerIsOpened)
  },
  methods: {
    closeDrawer() {
      this.$store.dispatch('drawer/closeDrawer')
    },
  },
}
</script>
<style lang="scss">
@import '~/assets/css/mobile-menu-drawer.scss';
@import '~/assets/css/drawer.css';
</style>
