var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawer-menu__content"},[_c('ul',[_c('client-only',[_vm._l((_vm.featuredItems),function(item){return _c('header-drawer-content-item',{key:item.position,attrs:{"item":item,"methods":_vm.drawerContentItemMethods}})}),_vm._v(" "),_vm._l((_vm.items),function(item){return _c('header-drawer-content-item',{key:item.position,attrs:{"item":item,"methods":_vm.drawerContentItemMethods}})}),_vm._v(" "),(_vm.$isBrStore)?_c('header-drawer-content-item',{attrs:{"item":{
          url: '/find-order',
          name: _vm.$t('header.track_order'),
        },"methods":_vm.drawerContentItemMethods}}):_vm._e(),_vm._v(" "),(_vm.$isBrStore)?_c('header-drawer-content-item',{attrs:{"item":{
          url: 'https://corporativo.gocase.com.br/',
          name: _vm.$t('header.corporate_gifts'),
        },"methods":_vm.drawerContentItemMethods}}):_vm._e(),_vm._v(" "),(_vm.$isBrStore)?_c('header-drawer-content-item',{attrs:{"item":{
          url: 'https://reseller.gocase.com.br/pt-BR/welcome',
          name: _vm.$t('header.become_reseller'),
        },"methods":_vm.drawerContentItemMethods}}):_vm._e(),_vm._v(" "),(_vm.$isBrStore)?_c('header-drawer-content-item',{attrs:{"item":{
          url: 'https://www.gocase.com.br/central-de-ajuda',
          icon: 'help-icon',
          name: _vm.$t('help_center'),
        },"methods":_vm.drawerContentItemMethods,"discrete":""}}):_vm._e()],2)],1),_vm._v(" "),(_vm.isLogged)?_c('ul',[_c('header-drawer-content-item',{attrs:{"item":{
        url: '/orders',
        icon: 'order-icon',
        name: _vm.$t('header.my-orders'),
      },"methods":_vm.drawerContentItemMethods,"discrete":""}}),_vm._v(" "),_c('header-drawer-content-item',{attrs:{"item":{
        url: '/account/edit',
        icon: 'edit-icon',
        name: _vm.$t('header.edit-account-info'),
      },"methods":_vm.drawerContentItemMethods,"discrete":""}}),_vm._v(" "),_c('header-drawer-content-item',{attrs:{"item":{
        url: '/addresses',
        icon: 'address-icon',
        name: _vm.$t('header.address-book'),
      },"methods":_vm.drawerContentItemMethods,"discrete":""}}),_vm._v(" "),_c('header-drawer-content-item',{attrs:{"item":{
        url: '/protocols',
        icon: 'protocol-icon',
        name: _vm.$t('header.protocols'),
      },"methods":_vm.drawerContentItemMethods,"discrete":""}}),_vm._v(" "),_c('header-drawer-content-item',{attrs:{"item":{
        url: '#',
        css_class: 'drawer-menu-item--logout',
      },"methods":_vm.drawerContentItemMethods}},[_c('p',[_vm._v(_vm._s(_vm.$t('header.hello'))+", "+_vm._s(_vm.firstName))]),_vm._v(" "),_c('logout-button',{attrs:{"icon":""}})],1)],1):_c('ul',[_c('header-drawer-content-item',{attrs:{"item":{
        url: '/login',
        icon: 'login-icon',
        name: _vm.$t('header.login'),
      },"methods":_vm.drawerContentItemMethods,"discrete":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }