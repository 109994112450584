import { mapState } from 'vuex'

export default {
  data() {
    return {
      selectedBrandId: null,
      selectedDeviceId: null,
    }
  },
  computed: {
    ...mapState(['siteStore']),
    brands() {
      let headerMenu = this.siteStore.header_menu

      // eslint-disable-next-line camelcase
      const substoreMenus = this.siteStore?.substores_menu
      const menu = substoreMenus?.brasil?.length
        ? substoreMenus?.brasil
        : substoreMenus?.us

      if (!!menu && this.$store.state.isUsStore) {
        headerMenu = menu
      }

      const brandMenu = this.$isGlobalStore
        ? this.$store.state.isUsStore
          ? headerMenu?.find((hm) => hm.id === 3637)
          : headerMenu?.find((hm) => hm.id === 705) // [Global] Phone Cases Header Menu ID
        : headerMenu?.find((hm) => hm.id === 325) // [BR] Phone Cases Header Menu ID

      if (!brandMenu) {
        if (this.$isGlobalStore) {
          this.$sentry.captureException(`[GLOBAL] Phone Cases Empty! Id 705`)
        } else {
          this.$sentry.captureException(`[BR] Phone Cases Empty! Id 325`)
        }

        return []
      }

      return brandMenu?.children
        ?.filter(
          (ch) =>
            ch.has_children &&
            !['tipos-de-capinhas', 'case-styles'].includes(ch.name_slug)
        )
        ?.sort((a, b) => a.position - b.position)
    },
    devices() {
      return (
        this.brands.find((br) => br.id === this.selectedBrandId) ||
        this.brands[0]
      )?.children
        ?.filter((dv) => dv.url !== '#')
        ?.sort((a, b) => a.position - b.position)
    },
    device() {
      return this.devices.find((dv) => dv.id === this.selectedDeviceId)
    },
    brand() {
      return this.brands.find((dv) => dv.id === this.selectedBrandId)
    },
  },
}
